<template>
  <div>
    <Navbar />
    <!-- ! Main Begin -->
    <main id="main" class="main">
      <section class="home-banner-section inner-banner cover-bg" id="banner">
        <div class="banner-caption">
          <div class="banner-caption-content text-white">
            <div
              class="
                banner-caption-text
                d-flex
                w-100
                align-items-center
                flex-warp
                h-100
                py-5
              "
            >
              <div class="container">
                <div class="banner-text">
                  <h1 class="title-border">Return <span>Policy</span></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="curved-section-main">
        <!-- ! Section start -->
        <section class="section curved-section no-min-height">
          <div class="section-shape section-shape-top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1920"
              height="84.752"
              viewBox="0 0 1920 84.752"
              class="shape-1"
            >
              <g transform="translate(0 -0.248)">
                <g transform="translate(0 -402)">
                  <path
                    d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="container section-container-padding">
            <h2>Return policy</h2>
            <p class="text-secondary">Last updated October 20, 2021</p>
            <p>
              Thank you for your purchase. We hope you are happy with your
              purchase. However, if you are not completely satisfied with your
              purchase for any reason, you may return it to us for store credit
              only. Please see below for more information on our return policy.
            </p>
            <h3>Returns</h3>
            <p>
              All returns must be postmarked within ten (10) days of the
              purchase date. All returned items must be in new and unused
              condition, with all original tags and labels attached.
            </p>
            <h3>Return process</h3>
            <p>
              To return an item, please email customer service at
              support@vactraxgkobal.com to obtain a Return Merchandise
              Authorization (RMA) number. After receiving a RMA number, place
              the item securely in its original packaging and RMA Number (issued
              by company), and mail your return to the following address:
            </p>
            <address>
              JAB Companies, Inc. <br />
              Attn: Returns<br />
              RMA #<br />
              1405 Silver Lake Road NW <br />
              Suite 20 - Returns Department <br />
              Saint Paul, MN 55112 <br />
              United States
            </address>
            <p>
              Please note, you will be responsible for all return shipping
              charges. We strongly recommend that you use a trackable method to
              mail your return.
            </p>
            <h3>Refunds</h3>
            <p>
              After receiving your return and inspecting the condition of your
              item, we will process your return. Please allow at least twenty
              (20) days from the receipt of your item to process your return. We
              will notify you by email when your return has been processed.
            </p>
            <h3>Exceptions</h3>
            <p>The following items cannot be returned:</p>
            <ul>
              <li>Printed ID Card</li>
              <li>Printed Passport Visa Sticke</li>
            </ul>
            <p>
              For defective or damaged products, please contact us at the
              contact details below to arrange a refund or exchange.
            </p>
            <h3>Questions</h3>
            <p>
              If you have any questions concerning our return policy, please
              contact us at: <br /><a href="tel:16123380794">+1 612-338-0794</a
              ><br />
              <a href="mailto:info@vaxtraxglobal.com">info@vaxtraxglobal.com</a>
            </p>
          </div>
        </section>
        <!-- ! Section end -->
      </div>
      <!-- ! Section start -->
      <section
        id="lets_talk"
        class="section curved-section lets-talk-section cover-bg text-white"
        style="
          background-image: url(../../assets/images/contact-blockimage.jpg);
        "
      >
        <!--   <section class="section curved-section lets-talk-section cover-bg"> -->
        <div class="container section-container-padding">
          <div class="section-top-bar d-flex">
            <div class="section-top-bar-container">
              <h2 class="section-title title-white">Let's <span>talk</span></h2>
            </div>
          </div>
          <div class="contact-box">
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              class="php-email-form h-100"
            >
              <div class="row">
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div class="validate"></div>
                </div>
              </div>
              <div class="form-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                />
                <div class="validate"></div>
              </div>
              <div class="form-group mb-4">
                <textarea
                  class="form-control"
                  name="message"
                  rows="5"
                  data-rule="required"
                  data-msg="Please write something for us"
                  placeholder="Message"
                ></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-orange" type="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <!-- ! Section end -->
    </main>
    <!-- ! Main end -->
    <!-- <div class="push"></div> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,

  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/screen.css";

#lets_talk {
  background-image: url("../../assets/images/contact-blockimage.jpg") !important;
}
#banner {
  background-image: url("../../assets/images/inneer-banner.jpg") !important;
}
</style>
